import { getMountedApps, navigateToUrl, } from 'single-spa';
import { portalShellManager } from './PortalShell';
export function addRootContainerListeners() {
    window.addEventListener('single-spa:before-routing-event', (evt) => {
        portalShellManager.getErrorContainer().innerHTML = ``;
    });
    window.addEventListener('single-spa:routing-event', (evt) => {
        // This is to handle the initial default page transition to portal_ service.
        // This is needed when we don't have an org or a service_ in the url.
        if (getMountedApps().length === 0) {
            const location = window.location;
            const parts = location.pathname.split('/').filter(Boolean);
            if (parts.length < 2) {
                navigateToUrl('/portal_');
            }
            else if (parts[parts.length - 1].endsWith('_')) {
                // Fallback by performing a redirect to alpha version of the service in case the service is not defined in the manifest
                window.location.href = `${window.__ROOT_CONFIG_ENV__.PORTAL_ORIGIN}` + window.location.pathname;
            }
            else {
                // Amend the single-spa-error-container to display the error message 
                portalShellManager.getErrorContainer().innerHTML = `
                    <portal-error-page type="404" header="Uh oh! Can't find it" description="The page you're looking for may have been removed, had its name changed, or is temporarily unavailable."/>
                `;
            }
        }
    });
}
export function updateRootContainerGlobals() {
    window.RootContainer = {
        portalDomain: `${window.__ROOT_CONFIG_ENV__.PORTAL_DOMAIN}`,
        portalShellManager,
    };
    portalShellManager.createShell();
}
