import { addErrorHandler, registerApplication, start, } from 'single-spa';
import manifest from '../public/manifest.json';
import { initAuthHandler } from './utils/AuthManger';
import { APOLLO_SHELL_ID, portalShellManager, } from './utils/PortalShell';
import { redirectToPreview, setOverrides, } from './utils/PrPreview';
import { addRootContainerListeners, updateRootContainerGlobals, } from './utils/RootContainer';
updateRootContainerGlobals();
Object.entries(manifest).forEach(([appName, config]) => {
    var _a;
    const { hideShell, activeRegex, activeWhen } = config;
    registerApplication({
        name: appName,
        app: () => {
            updateRootContainerGlobals();
            portalShellManager.setApShellAttribute('hide-shell', hideShell ? 'true' : 'false');
            return import(/* webpackIgnore: true */ appName).then(module => {
                if (module.get) {
                    // this is a module federation app, get the container factory and call it
                    return module.get('./Module').then(m => m());
                }
                return module;
            });
        },
        activeWhen: [
            (location) => {
                if (activeRegex) {
                    return !!location.pathname.toLowerCase().match(activeRegex);
                }
                return false;
            },
            ...((_a = activeWhen === null || activeWhen === void 0 ? void 0 : activeWhen.split(',')) !== null && _a !== void 0 ? _a : []),
        ],
        customProps: { domElement: document.getElementById(APOLLO_SHELL_ID) },
    });
});
addRootContainerListeners();
addErrorHandler((err) => {
    console.error('Error during single-spa lifecycle:', err);
    portalShellManager.getErrorContainer().innerHTML = `<portal-error-page type="404" header="Application Error" description="Error during single-spa lifecycle: ${err.message}"/>`;
});
const startApp = (hasUser) => {
    window.__IS_AUTHENTICATED__ = hasUser;
    if (hasUser) {
        redirectToPreview();
    }
    // At this point, we are authenticated and can start the app
    document.body.style.display = 'unset';
    start({ urlRerouteOnly: true });
};
// set import map overrides for PR preview
setOverrides();
// Core responsibility is to handle authentication and start the app
initAuthHandler(startApp);
